import React, { useState } from "react";
import DrawingReport from "./DrawingReport";
import ManualReport from "./ManualReport";
import StandardReport from "./StandardReport";
import SIRReport from "./SIRReport";
import StabilityCertificateReport from "./StabilityCertificate";
import ComplianceReport from "./Compliance";
import ExportDataModal from "./ExportDataModal";  
import LoginLogReport from "./LoginReport";

const MISReport = () => {
  const [reportType, setReportType] = useState("Drawing");
  const handleChange = (e) => {
    setReportType(e.target.value);
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="px-3 py-3 d-flex flex-column  flex-sm-row align-items-start align-items-sm-center justify-content-between">
                <div className="tbl-caption d-flex flex-column  flex-sm-row text-wrap align-items-start align-items-sm-center">
                  <h4 className="heading fw-bold mb-3 mb-md-1 me-3">
                    MIS Report
                  </h4>
                  <div className="form-group">
                    <div className="form-check custom-checkbox form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="reportType"
                        id="drawingRadio"
                        value="Drawing"
                        checked={reportType === "Drawing"}
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="drawingRadio"
                      >
                        Drawings
                      </label>
                    </div>
                    <div className="form-check custom-checkbox form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="reportType"
                        id="standardRadio"
                        value="Standard"
                        checked={reportType === "Standard"}
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="standardRadio"
                      >
                        Standards
                      </label>
                    </div>
                    <div className="form-check custom-checkbox form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="reportType"
                        id="manualRadio"
                        value="Document"
                        checked={reportType === "Document"}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="manualRadio">
                        Documents
                      </label>
                    </div>
                    <div className="form-check custom-checkbox form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="reportType"
                        id="sirRadio"
                        value="SIR"
                        checked={reportType === "SIR"}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="sirRadio">
                        SIR
                      </label>
                    </div>
                    <div className="form-check custom-checkbox form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="reportType"
                        id="scRadio"
                        value="Stability Certificate"
                        checked={reportType === "Stability Certificate"}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="scRadio">
                        Stability Certificate
                      </label>
                    </div>
                    <div className="form-check custom-checkbox form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="reportType"
                        id="complianceRadio"
                        value="Compliance"
                        checked={reportType === "Compliance"}
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="complianceRadio"
                      >
                        Compliance
                      </label>
                    </div>
                    <div className="form-check custom-checkbox form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="reportType"
                        id="LoginRadio"
                        value="Login"
                        checked={reportType === "Login"}
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="LoginRadio"
                      >
                        Login
                      </label>
                    </div>
                  </div>
                </div>
                <ExportDataModal exportType={reportType} />
              </div>
              {reportType === "Drawing" ? (
                <DrawingReport />
              ) : reportType === "Standard" ? (
                <StandardReport />
              ) : reportType === "Document" ? (
                <ManualReport />
              ) : reportType === "SIR" ? (
                <SIRReport />) :
                reportType === "Stability Certificate" ?
                  (<StabilityCertificateReport />) :
                reportType === "Compliance" ?
                  (<ComplianceReport />) : <LoginLogReport />
                }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MISReport;
