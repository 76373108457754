import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DynamicSelectInput from "../../constant/SelectInput";
import { useFormik } from "formik";
import { addUserSchema } from "../../constant/schemas/UserSchema";
import { createUser } from "../../../services/UserService";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";

function AddUser({ userRole }) {
  const initialValues = {
    personnel_number: "",
    password: "",
    full_name: "",
    email: "",
    phone_number: "",
    role: userRole !== "SuperAdmin" ? "User" : "Admin",
    designation: "",
    is_approver: "NO",
    is_final_approver: "NO",
    department_id: "",
    drawing_permission: { add_drawing: false, ps: false, fdr: false, misc: false, disable_dwg_file: true, download_drawing: false, view_layout: false },
  };
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSelectChange = (name, value, _) => {
    setValues({ ...values, [name]: value });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleFormSubmit = async (values, action) => {
    try {
      setIsLoading(true);
      const resp = await createUser(values);
      toast.success(resp.data.message);
      action.resetForm();
      navigate("/user");
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsLoading(false);
  };

  const handleDrawingPermission = (e) => {
    const { name, checked } = e.target
    if (name === "disable_dwg_file" && checked) {
      setValues({ ...values, drawing_permission: { ...values.drawing_permission, [name]: checked, download_drawing: false } });
    } else {
      setValues({ ...values, drawing_permission: { ...values.drawing_permission, [name]: checked } });
    }
  }

  const handleAllPermission = (name, checked, defaultValue) => {
    let permValue
    if (['drawing_permission', 'standard_permission', 'document_permission'].includes(name)) {
      if (checked) {
        Object.keys(defaultValue).forEach(key => defaultValue[key] = !defaultValue[key]);
        permValue = defaultValue
      } else {
        permValue = defaultValue
      }
    }
    if (permValue !== undefined) {
      setValues({ ...values, [name]: permValue });
    }
  }

  const PermissionRadioButton = ({ label, name, checkValue, handleChange, disabled = false }) => {
    return (
      <div className="mb-2 ms-3">
        <div className="form-check custom-checkbox mb-2 form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            name={name}
            id={`is_view_${name}`}
            checked={checkValue}
            disabled={disabled}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor={`is_view_${name}`}>{label}</label>
        </div>
      </div>
    )
  }

  const {
    values,
    errors,
    touched,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: addUserSchema,
    onSubmit: handleFormSubmit,
  });

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header ">
                <div className="d-flex  align-items-center">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-circle"
                    onClick={() => navigate("/user")}
                  >
                    <i className="fa-solid fa-arrow-left"></i>
                  </button>
                  <h4 className="mb-0 ms-2">Add User</h4>
                </div>
              </div>
              <div className="card-body">
                <Form onSubmit={handleSubmit} className="px-4 py-2">
                  <div className="row">
                    <div className="col-xl-6 col-sm-12">
                      <div className="mb-3">
                        <label
                          htmlFor="personnel_number"
                          className="form-label"
                        >
                          Personnel No. <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="personnel_number"
                          placeholder="Enter Personnel Number"
                          name="personnel_number"
                          value={values.personnel_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.personnel_number && touched.personnel_number ? (
                          <div className="text-danger small mt-1">
                            {errors.personnel_number}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                          Password <span className="text-danger">*</span>
                        </label>
                        <div className="input-group ">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control border border-end-0"
                            id="password"
                            placeholder="Enter Password"
                            name="password"
                            autoComplete="new-password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <button
                            className="btn border border-start-0 border-light-subtle pb-2 "
                            type="button"
                            onClick={handleTogglePassword}
                          >
                            {showPassword ? (
                              <i className="fa-regular fa-eye-slash"></i>
                            ) : (
                              <i className="fa-regular fa-eye"></i>
                            )}
                          </button>
                        </div>
                        {errors.password && touched.password ? (
                          <div className="text-danger small mt-1">
                            {errors.password}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="full_name" className="form-label">
                          Full Name<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="full_name"
                          name="full_name"
                          placeholder="Enter Full Name"
                          value={values.full_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.full_name && touched.full_name ? (
                          <div className="text-danger small mt-1">
                            {errors.full_name}
                          </div>
                        ) : null}
                      </div>

                      {userRole === "SuperAdmin" && (
                        <>
                          <div className="mb-3">
                            <label>
                              Is Approver <span className="text-danger">*</span>
                            </label>
                            <div className="form-check custom-checkbox mb-2 ms-3 form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="is_approver"
                                id="approverYesRadio"
                                value="YES"
                                checked={values.is_approver === "YES"}
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="approverYesRadio"
                              >
                                YES
                              </label>
                            </div>
                            <div className="form-check custom-checkbox mb-2 form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="is_approver"
                                id="approverNoRadio"
                                value="NO"
                                checked={values.is_approver === "NO"}
                                onChange={(e) => {
                                  if(e.target.checked){
                                    setValues({...values, is_approver:"NO", is_final_approver:"NO"})
                                  }
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="approverNoRadio"
                              >
                                NO
                              </label>
                            </div>
                          </div>

                          {values.is_approver === "YES" && (
                            <div className="mb-3">
                              <label>
                                Final Approver <span className="text-danger">*</span>
                              </label>
                              <div className="form-check custom-checkbox mb-2 ms-3 form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="is_final_approver"
                                  id="finalapproverYesRadio"
                                  value="YES"
                                  checked={values.is_final_approver === "YES"}
                                  onChange={handleChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="finalapproverYesRadio"
                                >
                                  YES
                                </label>
                              </div>
                              <div className="form-check custom-checkbox mb-2 form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="is_final_approver"
                                  id="finalapproverNoRadio"
                                  value="NO"
                                  checked={values.is_final_approver === "NO"}
                                  onChange={handleChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="finalapproverNoRadio"
                                >
                                  NO
                                </label>
                              </div>
                            </div>
                          )}
                          <div className="mb-3">
                            <label>
                              User Role <span className="text-danger">*</span>
                            </label>
                            <div className="form-check custom-checkbox mb-2 ms-3 form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="role"
                                id="adminRadio"
                                value="Admin"
                                checked={values.role === "Admin"}
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="adminRadio"
                              >
                                Admin
                              </label>
                            </div>
                            <div className="form-check custom-checkbox mb-2 form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="role"
                                id="userRadio"
                                value="User"
                                checked={values.role === "User"}
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="userRadio"
                              >
                                User
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                    </div>
                    <div className="col-xl-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Enter Email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email ? (
                          <div className="text-danger small mt-1">
                            {errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="phone_number" className="form-label">
                          Phone Number <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="phone_number"
                          placeholder="Enter Phone Number"
                          name="phone_number"
                          value={values.phone_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.phone_number && touched.phone_number ? (
                          <div className="text-danger small mt-1">
                            {errors.phone_number}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <DynamicSelectInput
                          setParentInputValue={handleSelectChange}
                          endpoint_name="search/department"
                          name={"department_id"}
                          id={"department_id"}
                          label={"Department"}
                          placeholder={"Select Department"}
                          isRequired={true}
                        />
                        {errors.department_id && touched.department_id ? (
                          <div className="text-danger small mt-1">
                            {errors.department_id}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="designation" className="form-label">
                          Designation<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="designation"
                          placeholder="Enter Designation"
                          name="designation"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.designation}
                        />
                        {errors.designation && touched.designation ? (
                          <div className="text-danger small mt-1">
                            {errors.designation}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {values.role === "User" ? (
                    <div className="my-3 px-2">
                      <div className="d-flex align-items-center mb-3">
                        <input type="checkbox"
                          className="form-check-input"
                          name="drawing_permission"
                          checked={Object.keys(values.drawing_permission).filter(key => key !== "disable_dwg_file").every(key => values.drawing_permission[key])}
                          onChange={(e) => handleAllPermission('drawing_permission', e.target.checked, initialValues.drawing_permission)}
                        />
                        <h5 className="fst-italic mb-0 ms-2 fw-semibold">Drawing Permission:</h5>
                      </div>
                      <div className="row">

                        <div className="col-lg-2 col-md-6 col-sm-12 p-2">
                          <PermissionRadioButton
                            label={"Add Drawing"}
                            name={'add_drawing'}
                            checkValue={values.drawing_permission.add_drawing}
                            handleChange={handleDrawingPermission}
                          />
                        </div>

                        <div className="col-lg-2 col-md-6 col-sm-12 p-2">
                          <PermissionRadioButton
                            label={"Disable DWG File"}
                            name={'disable_dwg_file'}
                            checkValue={values.drawing_permission.disable_dwg_file}
                            handleChange={handleDrawingPermission}
                          />
                        </div>

                        <div className="col-lg-2 col-md-6 col-sm-12 p-2">
                          <PermissionRadioButton
                            label={"Download Drawing"}
                            name={'download_drawing'}
                            checkValue={values.drawing_permission.download_drawing}
                            handleChange={handleDrawingPermission}
                            disabled={values.drawing_permission.disable_dwg_file}
                          />
                        </div>

                        <div className="col-lg-2 col-md-6 col-sm-12 p-2">
                          <PermissionRadioButton
                            label={"View Layout"}
                            name={'view_layout'}
                            checkValue={values.drawing_permission.view_layout}
                            handleChange={handleDrawingPermission}
                          />
                        </div>

                        <div className="col-lg-2 col-md-6 col-sm-12 p-2">
                          <PermissionRadioButton
                            label={"View PS Drawing"}
                            name={'ps'}
                            checkValue={values.drawing_permission.ps}
                            handleChange={handleDrawingPermission}
                          />
                        </div>

                        <div className="col-lg-2 col-md-6 col-sm-12 p-2">
                          <PermissionRadioButton
                            label={"View FDR Drawing"}
                            name={'fdr'}
                            checkValue={values.drawing_permission.fdr}
                            handleChange={handleDrawingPermission}
                          />
                        </div>

                        <div className="col-lg-2 col-md-6 col-sm-12 p-2">
                          <PermissionRadioButton
                            label={"View MISC Drawing"}
                            name={'misc'}
                            checkValue={values.drawing_permission.misc}
                            handleChange={handleDrawingPermission}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="d-flex align-items-center justify-content-center mt-2">
                    <button type="submit" className="btn btn-primary me-1 w-50" disabled={isLoading}>
                      {isLoading ? (<span><Spinner size="sm" className="me-2" />Creating...</span>) : "Create"}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


const mapStateToProps = (state) => {
  return {
    userRole: state.auth.user?.role || "Admin"
  };
};

export default connect(mapStateToProps)(AddUser);
