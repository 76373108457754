import axiosInstance from './AxiosInstance';

export function dashboard() {
    return axiosInstance.get('api/dashboard');
}

export function pieChartApi() {
    return axiosInstance.get('api/pie_chart_count');
}

export function activityDashboard() {
    return axiosInstance.get('api/activity_dashboard');
}

export function LoginLogGraph() {
    return axiosInstance.get(`api/login_log_graph`);
}

export function drawingLogGraph(time_period = "weekly") {
    return axiosInstance.get(`api/drawing_log_graph?time_period=${time_period}`);
}

export function standardLogGraph(time_period = "weekly") {
    return axiosInstance.get(`api/standard_log_graph?time_period=${time_period}`);
}

export function manualLogGraph(time_period = "weekly") {
    return axiosInstance.get(`api/manual_log_graph?time_period=${time_period}`);
}

export function siLogGraph(time_period = "weekly", si_type) {
    return axiosInstance.get(`api/si_log_graph?time_period=${time_period}&si_type=${si_type}`);
}
