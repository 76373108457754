import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import DynamicSelectInput from "../../constant/SelectInput";
import { useNavigate } from "react-router-dom";
import { formatFileSize } from "../../../utils";
import { toast } from "react-toastify";
import Pagination from "../../constant/Pagination";
import {
  getStabilityCertificationList,
  createStabilityCertification,
  updateStabilityCertification,
  updateStabilityCertificationStatus,
} from "../../../services/SIService";
import TableLoaderEffect from "../../constant/TableLoader";

function StabilityCertificationPage({ userRole }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const [selectedSC, setSelectedSC] = useState(null);
  const [confirmMSG, setConfirmMSG] = useState("");

  const [scInput, setSCInput] = useState({
    certificate_number: "",
    department_id: "",
    unit_id: "",
    file: "",
  });

  const [scInputError, setSCInputError] = useState({
    certificate_number: false,
    department_id: false,
    file: false,
  });
  const [isModalOpen, setIsModalOpen] = useState({ open: false, type: "Add" });
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [total, setTotal] = useState(0);
  const recordsPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    if (!searchQuery.length > 0) {
      setIsLoading(true);
    }
    getStabilityCertificationList(currentPage, recordsPage, searchQuery)
      .then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.count);
        setIsLoading(false);
        setIsSearchLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
        setIsSearchLoading(false);
      });
  }, [currentPage, searchQuery]);

  const toggleModal = (type = "Add", item = null) => {
    let open = isModalOpen.open;

    if (type === "Add") {
      open = !open;
    } else if (type === "Edit" && item) {
      setSelectedSC(item);

      setSCInput({
        id: item.id,
        certificate_number: item.certificate_number,
        department_id: item.department ? item.department.department_id : "",
        unit_id: item.unit ? item.unit.unit_id : "",
        file: "",
        file_data: item.attachment,
      });
      open = true;
    }
    if (isModalOpen.open && item === null) {
      setSCInput({
        certificate_number: "",
        department_id: "",
        unit_id: "",
        file: "",
      });

      setSCInputError({
        certificate_number: false,
        department_id: false,
        file: false,
      });

      setSelectedSC(null);
    }
    setIsModalOpen({ type, open });
  };

  const toggleArchiveModal = () => {
    if (showArchiveModal) {
      setSelectedSC(null);
    }
    setShowArchiveModal(!showArchiveModal);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setIsSearchLoading(true);
    setCurrentPage(1);
    setSearchQuery(value);
  };

  const handleFiles = (file_list) => {
    if (file_list.length) {
      const file = file_list[0];
      const file_ext = file.name.split(".").pop().toUpperCase();
      if (file_ext !== "PDF") {
        toast.error("Please choose a valid PDF file.");
      } else {
        setSCInput({ ...scInput, file: file });
        setSCInputError({ ...scInputError, file: false });
      }
    }
  };

  const handleFileInputChange = (e) => {
    const filelist = e.target.files || [];
    handleFiles(filelist);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedSCInput = { ...scInput, [name]: value };
    const updatedSCInputError = {
      ...scInputError,
      [name]: value.trim() === "",
    };
    setSCInput(updatedSCInput);
    setSCInputError(updatedSCInputError);
  };

  const handleSelectChange = (name, value, _) => {
    if (name === "department_id") {
      const updatedSCInputError = { ...scInputError, [name]: !value };
      setSCInputError(updatedSCInputError);
    }
    setSCInput({ ...scInput, [name]: value });
  };
  const handlePagination = (page) => {
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const scInputErrorCopy = { ...scInputError };
    let hasError = false;
    Object.keys(scInput).forEach((key) => {
      if (scInput[key] === "" && key !== "unit_id") {
        if (key === "file" && isModalOpen.type === "Edit") {
          return;
        }
        scInputErrorCopy[key] = true;
        hasError = true;
      }
    });
    if (hasError) {
      setSCInputError(scInputErrorCopy);
      return;
    }

    if (isModalOpen.type === "Add") {
      try {
        setIsSubmitLoading(true);
        const reqBody = new FormData();
        Object.entries(scInput).forEach(([fieldName, value]) => {
          reqBody.append(fieldName, value);
        });
        const resp = await createStabilityCertification(reqBody);
        const { message, results } = resp.data;
        toast.success(message);
        setData([results, ...data]);
        toggleModal();
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        setIsSubmitLoading(false);
      }
    } else {
      try {
        setIsSubmitLoading(true);
        const reqBody = new FormData();
        Object.entries(scInput).forEach(([fieldName, value]) => {
          if (fieldName !== "file_data") {
            reqBody.append(fieldName, value);
          }
        });
        const resp = await updateStabilityCertification(reqBody);
        const { results, message } = resp.data;
        setData(
          data.map((item) => (item.id === results.id ? { ...results } : item))
        );
        toast.success(message);
        toggleModal();
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        setIsSubmitLoading(false);
      }
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitLoading(true);
    try {
      const resp = await updateStabilityCertificationStatus({
        id: selectedSC.id,
        archive: !selectedSC.is_archive,
        archive_reason: confirmMSG,
      });
      toast.success(resp.data.message);
      setConfirmMSG("");
      if (!data.is_archive) {
        navigate("/archive-stability-certificate");
      } else {
        setData({ ...data, is_archive: !data.is_archive });
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error.response.data.message);
    }
    setIsSubmitLoading(false);
    toggleModal(false);
  };

  const viewPDFFile = (id) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/api/stability_certificate_file_download/${id}`
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error fetching or opening file:', error);
    }
  };

  return (
    <>
      <div>
        <div className="card-body p-0">
          <div className="d-flex justify-content-between text-wrap flex-column  flex-sm-row align-items-start align-items-sm-center p-3">
            <h4 className="heading mb-3 mb-md-0">STABILITY CERTIFICATE</h4>
            <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
              <div className="me-3 mb-md-0">
                <div className="input-group tp-input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    value={searchQuery}
                    aria-describedby="basic-search"
                    onChange={handleSearchChange}
                  />
                  <div className="input-group-text" id="basic-search">
                    {isSearchLoading && <Spinner variant="primary" size="sm" />}
                    {!isSearchLoading && searchQuery.length > 0 && (
                      <span
                        className="c-pointer"
                        onClick={() => {
                          setCurrentPage(1);
                          setSearchQuery("");
                        }}
                      >
                        <i className="fa-solid fa-xmark text-primary"></i>
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {userRole !== "User" && (
                <button
                  className="btn btn-primary mt-3 mt-md-0"
                  onClick={() => toggleModal()}
                >
                  Add
                </button>
              )}
            </div>
          </div>
          <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
            <div
              id="employee-tbl_wrapper"
              className="dataTables_wrapper no-footer"
            >
              <table
                id="empoloyees-tblwrapper"
                className="table ItemsCheckboxSec dataTable no-footer mb-0 table-striped"
              >
                <thead>
                  <tr>
                    <th>Certificate Number</th>
                    <th>Department</th>
                    <th>Unit</th>
                    <th>Status</th>
                    <th>Attachment</th>
                    {userRole !== "User" && <th>Action</th>}
                  </tr>
                </thead>

                <tbody>
                  {isLoading ? (
                    <TableLoaderEffect colLength={userRole === "User" ? 5 : 6} />
                  ) : (
                    data.map((item, index) => (
                      <tr key={index}>
                        <td>{item.certificate_number}</td>
                        <td>{item.department?.name || ""}</td>
                        <td>{item.unit?.name || ""}</td>
                        <td>
                          {item.is_approved === true ? (
                            <span
                              className={`badge light border-0 badge-success badge-sm`}
                            >
                              Approved
                            </span>
                          ) : (
                            <span
                              className={`badge light border-0 badge-info badge-sm`}
                            >
                              Pending
                            </span>
                          )}
                        </td>
                        <td>
                          {item.attachment ? (
                            <Button
                              variant="btn btn-primary tp-btn btn-sm"
                              onClick={() => viewPDFFile(item.id)}
                            >
                              <span className="d-flex align-items-center">
                                <i className="fa-regular fa-eye me-2"></i>
                                <span>View Attachment</span>
                              </span>
                            </Button>
                          ) : null}
                        </td>
                        <td>
                          {userRole !== "User" && (
                            <>
                              <Button
                                onClick={() => toggleModal("Edit", item)}
                                variant="btn btn-primary tp-btn btn-sm"
                              >
                                <i className="fa-regular fa-pen-to-square me-1"></i>
                                Edit
                              </Button>
                              <Button
                                onClick={() => navigate(`/si/${item.id}`)}
                                variant="btn btn-primary tp-btn btn-sm"
                              >
                                <i className="fa-solid fa-clock-rotate-left me-1"></i> View
                                Log
                              </Button>
                              <Button
                                onClick={() => {
                                  setSelectedSC(item);
                                  toggleArchiveModal();
                                }}
                                variant="btn btn-danger tp-btn btn-sm"
                              >
                                <i className="fa-solid fa-box-archive me-1"></i>
                                {item?.is_archive ? "Unarchive" : "Archive"}
                              </Button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              currentPage={currentPage}
              recordsPage={recordsPage}
              dataLength={total}
              handlePagination={handlePagination}
            />
          </div>
        </div>
      </div>
      {/* card end */}

      <Modal
        className="modal fade"
        id="exampleModal1"
        show={isModalOpen.open && isModalOpen.type === "Add"}
        onHide={() => toggleModal()}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Add Stability Certificate
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={() => toggleModal()}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-xl-12">
                  <label className="form-label">
                    Certificate Number<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    autoFocus
                    id="certificate_number"
                    name="certificate_number"
                    className={`form-control mb-3 ${
                      scInputError.certificate_number ? "is-invalid" : ""
                    }`}
                    placeholder="Enter Certificate Number"
                    value={scInput.certificate_number}
                    onChange={handleChange}
                  />
                  {scInputError.certificate_number && (
                    <div className="text-danger mb-3">
                      Certificate No. is required.
                    </div>
                  )}
                </div>

                <div className="col-xl-12">
                  <label htmlFor="department_id" className="form-label">
                    Department<span className="text-danger">*</span>
                  </label>
                  <DynamicSelectInput
                    setParentInputValue={handleSelectChange}
                    endpoint_name="search/department"
                    name={"department_id"}
                    id={"department_id"}
                    is_invalid={scInputError.department_id}
                    placeholder={"Select Department"}
                    isRequired={true}
                  />
                  {scInputError.department_id && (
                    <div className="text-danger mb-3">
                      Department is required.
                    </div>
                  )}
                </div>
                <div className="col-xl-12">
                  <label htmlFor="unit" className="form-label">
                    Unit
                  </label>
                  <DynamicSelectInput
                    setParentInputValue={handleSelectChange}
                    endpoint_name="search/unit"
                    name={"unit_id"}
                    id={"unit_id"}
                    placeholder={"Select Unit"}
                    isRequired={true}
                  />
                </div>
                <div className="col-xl-12">
                  <label htmlFor="pdfFile" className="form-label">
                    Attachment<span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    className={`form-control ${
                      scInputError.file && "is-invalid"
                    }`}
                    id="file"
                    name="file"
                    onChange={handleFileInputChange}
                    accept=".pdf"
                  />
                  {scInputError.file && (
                    <div className="text-danger mb-3">File is required.</div>
                  )}
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-block btn-sm btn-primary mt-3 float-end"
                disabled={isSubmitLoading}
              >
                {isSubmitLoading ? (
                  <span>
                    <Spinner size="sm" className="me-2" />
                    Creating...
                  </span>
                ) : (
                  "Create"
                )}
              </button>
            </Form>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal fade"
        id="exampleModal1"
        show={isModalOpen.open && isModalOpen.type === "Edit"}
        onHide={() => toggleModal()}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Edit Stability Certificate
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={() => toggleModal()}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-xl-12">
                  <label className="form-label">
                    Certificate Number<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    autoFocus
                    id="certificate_number"
                    name="certificate_number"
                    className={`form-control mb-3 ${
                      scInputError.certificate_number ? "is-invalid" : ""
                    }`}
                    placeholder="Enter Certificate Number"
                    value={scInput.certificate_number}
                    onChange={handleChange}
                  />
                  {scInputError.certificate_number && (
                    <div className="text-danger mb-3">
                      Certificate No. is required.
                    </div>
                  )}
                </div>

                <div className="col-xl-12">
                  <label htmlFor="department_id" className="form-label">
                    Department <span className="text-danger">*</span>
                  </label>
                  <DynamicSelectInput
                    setParentInputValue={handleSelectChange}
                    endpoint_name="search/department"
                    name={"department_id"}
                    id={"department_id"}
                    placeholder={"Select Department"}
                    defaultValue={
                      selectedSC?.department
                        ? {
                            value: selectedSC.department.department_id,
                            label: selectedSC.department.name,
                          }
                        : null
                    }
                    is_invalid={scInputError.department_id}
                  />
                  {scInputError.department_id && (
                    <div className="text-danger mb-3">
                      Department is required.
                    </div>
                  )}
                </div>
                <div className="col-xl-12">
                  <label htmlFor="unit" className="form-label">
                    Unit
                  </label>
                  <DynamicSelectInput
                    setParentInputValue={handleSelectChange}
                    endpoint_name="search/unit"
                    name={"unit_id"}
                    id={"unit_id"}
                    placeholder={"Select Unit"}
                    isRequired={true}
                    defaultValue={
                      selectedSC?.unit
                        ? {
                            value: selectedSC.unit.unit_id,
                            label: selectedSC.unit.name,
                          }
                        : null
                    }
                  />
                </div>
                <div className="col-xl-12">
                  <label for="file" className="form-label">
                    Attachment
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    id="file"
                    name="file"
                    onChange={handleFileInputChange}
                    accept=".pdf"
                  />
                  {scInput.file_data ? (
                    <div className="text-warning mt-1 fst-italic">
                      Existing File {scInput.file_data.name}
                      <span className="me-2">
                        {formatFileSize(scInput.file_data.size)}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-block btn-sm btn-primary mt-3 float-end"
                disabled={isSubmitLoading}
              >
                {isSubmitLoading ? (
                  <span>
                    <Spinner size="sm" className="me-2" />
                    Updating...
                  </span>
                ) : (
                  "Update"
                )}
              </button>
            </Form>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal modal-sm fade"
        id="archiveModal"
        show={showArchiveModal}
        centered={true}
        onHide={toggleArchiveModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              {selectedSC?.is_archive ? "Unarchive" : "Archive"} STABILITY
              CERTIFICATE
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={toggleArchiveModal}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={handleOnSubmit}>
              <div className="row">
                <div className="mb-3">
                  <label htmlFor="confirmMSG" className="form-label">
                    {selectedSC?.is_archive
                      ? "Type 'confirm' to unarchive"
                      : "Reason For Archive"}
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="confirmMSG"
                    name="confirmMSG"
                    placeholder={
                      selectedSC?.is_archive
                        ? "Type 'confirm' to unarchive"
                        : "Reason For Archive"
                    }
                    value={confirmMSG}
                    onChange={(e) => setConfirmMSG(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-block btn-sm btn-primary mt-3 float-end"
                disabled={
                  isSubmitLoading ||
                  confirmMSG.trim() === "" ||
                  (selectedSC?.is_archive
                    ? confirmMSG.toLocaleLowerCase() !== "confirm"
                    : false)
                }
              >
                {isSubmitLoading ? (
                  <span>
                    <Spinner variant="light" size="sm" className="me-2" />
                    {selectedSC?.is_archive ? "Unarchive" : "Archive"}
                  </span>
                ) : selectedSC?.is_archive ? (
                  "Unarchive"
                ) : (
                  "Archive"
                )}
              </button>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userRole: state.auth.user?.role || "User",
  };
};
export default connect(mapStateToProps)(StabilityCertificationPage);
