import React, { useState } from "react";
import { connect } from "react-redux";
import { Tab, Nav, NavDropdown } from "react-bootstrap";
import ChangePassword from "./ChangePassword";
import LoginActivity from "./LoginActivity";
import DrawingLogPage from "./DrawingLog";
import StandardLogPage from "./StandardLog";
import ManualLogPage from "./ManualLog";
import SIRLogPage from "./SIRLog";
import ComplianceLogPage from "./ComplianceLog";
import StabilityCertificateLogPage from "./StabilityCertificateLog";
import { getUserNameIcon } from "../../../utils";
import ChangeProfilePhoto from "./ChangeProfilePhoto";
import EditProfileModal from "./editProfileModal";
import SetupTwoFactorAuth from "./SetupTwoFactorAuth";
import UploadSignaturePhoto from "./UploadSignaturePhoto";
import ResetTwoFactorAuth from "./ResetTwoFactorAuth";

function ProfileDetails({ user }) {

  const [activeKey, setActiveKey] = useState("login_activity")
  const handleSelect = (eventKey) => setActiveKey(eventKey);


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <div className="card">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div className="d-flex  align-items-center">
                    <h4 className="mb-0">Your Profile</h4>
                  </div>
                  <div>
                    <EditProfileModal email={user?.email} phone_number={user?.phone_number} designation={user?.designation} />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="border-bottom pb-2">
                  <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
                    <div className="d-flex flex-column">
                      {user?.profile_photo ? (
                        <div className="profile-pic-lg">
                          <img src={`${process.env.REACT_APP_BASE_URL}${user.profile_photo}`} alt="profile" />
                        </div>
                      ) : (
                        <div className="avatar-lg-text">
                          {getUserNameIcon(user?.full_name)}
                        </div>
                      )}
                      {/* <ChangeProfilePhoto isPhoto={user.profile_photo} /> */}
                    </div>

                    <ul className="px-3">
                      <li className="my-3">
                        <span className="fw-normal">Personnel Number:</span>
                        <span className="fw-bold ms-2">{user?.personnel_number}</span>
                      </li>
                      <li className="my-3 d-flex">
                        <span className="fw-normal">Full Name:</span>
                        <span className="fw-bold ms-2">{user?.full_name}</span>
                      </li>
                      <li className="my-3">
                        <span className="fw-normal">Email:</span>
                        <span className="fw-bold ms-2">{user?.email}</span>
                      </li>
                      <li className="my-3">
                        <span className="fw-normal">Phone Number:</span>
                        <span className="fw-bold ms-2">{user?.phone_number}</span>
                      </li>
                    </ul>
                  </div>
                  <ChangeProfilePhoto isPhoto={user.profile_photo} />
                </div>

                {user.is_an_approver && (
                  <div className="border-bottom pb-2 mt-2">
                    { }
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="fw-bold">Approver</span>
                      {user.is_enable_two_factor ? (<ResetTwoFactorAuth />)
                        : user.approver_signature_photo ? <SetupTwoFactorAuth /> : (<button className="btn btn-primary btn-sm" disabled>Setup 2FA</button>)
                      }
                    </div>
                    {user.approver_signature_photo ? (
                      <div className="pt-3 d-flex align-items-md-center align-items-start justify-content-between flex-md-row flex-column">
                        <div className="mb-md-0 mb-3">
                          <img src={`${process.env.REACT_APP_BASE_URL}${user.approver_signature_photo}`} width={150} height={50} alt="signature" className="img rounded" />
                        </div>
                        <UploadSignaturePhoto />
                      </div>
                    ) : (
                      <div className="pt-3 d-flex align-items-center flex-column">
                        <span className="text-danger mb-3 fw-semibold">Upload your approval Signature first, then you can setup Two Factor Approval Authentication</span>
                        <UploadSignaturePhoto />
                      </div>
                    )}
                  </div>
                )}

                <ul>
                  <li className="my-3">
                    <span className="fw-bold">Designation:</span>
                    {user?.designation}
                  </li>
                  <li className="my-3">
                    <span className="fw-bold">Role:</span>
                    <span className={`badge light border-0  ${user?.role === "Admin" ? 'badge-primary' : 'badge-info'} badge-sm ms-2`}>
                      {user?.role}
                    </span>
                  </li>
                  <li className="my-3">
                    <span className="fw-bold">Status: </span>
                    <span
                      className={`badge light border-0 ${user?.status === "Active"
                        ? "badge-success"
                        : "badge-danger"
                        } badge-sm ms-2`}
                    >
                      {user?.status}
                    </span>
                  </li>

                  <li className="my-3">
                    <span className="fw-bold">Department:</span>
                    {user?.department?.name}
                  </li>
                  {user.is_final_approver &&
                    <li className="my-3">
                      <span className="fw-bold">Final Approver:</span>YES
                    </li>
                  }
                </ul>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="my-2 py-2 d-flex align-items-center justify-content-between">
                  <h5 className="mb-md-0">Change Password</h5>
                  <ChangePassword />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="card">
              <div className="custom-tab-1">

                <Tab.Container activeKey={activeKey} onSelect={handleSelect}>
                  <Nav
                    as="ul"
                    className="nav-pills mb-2 light border-bottom px-4 pt-3"
                  >
                    <Nav.Item as="li">
                      <Nav.Link eventKey="login_activity" className="pb-3 fw-semibold fs-5">
                        Login Activity
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link eventKey="drawing_log" className="pb-3 fw-semibold fs-5">
                        Drawing Log
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content className="">
                    <Tab.Pane eventKey={"login_activity"}><LoginActivity /></Tab.Pane>
                    <Tab.Pane eventKey={"drawing_log"}><DrawingLogPage /></Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
export default connect(mapStateToProps)(ProfileDetails);
