import React, { useState } from "react";
import { toast } from "react-toastify";
import { Modal, Form, Spinner } from "react-bootstrap";
import { setupTwoFactorAuthApi } from "../../../services/UserService";
import { useDispatch } from "react-redux";
import QRCode from "react-qr-code";
import { updateProfileAction } from "../../../store/actions/AuthActions";
function SetupTwoFactorAuth() {
  const [otpUrl, setOtpUrl] = useState('');
  const [otp, setOtp] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [qrcodeLoading, setQrcodeLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch()

  const toggleModal = () => {
    if (!showModal) {
      setQrcodeLoading(true)
      setupTwoFactorAuthApi()
        .then((resp) => {
          setOtpUrl(resp.data.results);
          setQrcodeLoading(false)
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message)
            setQrcodeLoading(false)
          } else {
            console.log(error);
          }
        });
    } else {
      setOtpUrl('');
      setOtp('');
    }
    setShowModal(!showModal);
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    if (submitLoading) {
      return
    }
    if (otp) {
      setSubmitLoading(true)
      setupTwoFactorAuthApi(otp)
        .then((resp) => {
          dispatch(updateProfileAction({ is_enable_two_factor: true }))
          setSubmitLoading(false)
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message)
            setSubmitLoading(false)
          } else {
            console.log(error);
          }
        });
    } else {
      toast.error("Enter Verification code")
    }
  }

  return (
    <>
      <button
        className="btn btn-primary btn-sm cursor-pointer fw-bolder"
        onClick={toggleModal}
      >
        Setup 2FA
      </button>
      <Modal
        className="modal fade"
        id="exampleModal1"
        show={showModal}
        centered={true}
        onHide={toggleModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Setup Two Factor Approval Athentication
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>

          <div className="modal-body">
            <Form onSubmit={handleSubmit}>
              <div className="mb-3">
                {qrcodeLoading ? (<div>
                  <h4>Loading... QR Code</h4>
                </div>) : (
                  <div className="d-flex align-items-center justify-content-center">
                  {otpUrl && <QRCode value={otpUrl} size={256} viewBox={`0 0 256 256`} className="shadow-sm p-3 mb-5 bg-white rounded" />}
                </div>)}
              </div>
              <div className="mb-3">
                <label htmlFor="verification_code" className="form-label">
                  Verification Code <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="verification_code"
                  placeholder="Enter Confirm Password"
                  name="verification_code"
                  autoComplete="new-password"
                  value={otp}
                  required
                  onChange={(e) => setOtp(e.target.value)}
                />
              </div>
              <button
                type="submit"
                className="btn btn-block btn-sm btn-primary mt-3"
                disabled={submitLoading}
              >
                {submitLoading ? (<span><Spinner size="sm" className="me-2" />Verifying...</span>) : "Veirfy"}
              </button>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SetupTwoFactorAuth;
