import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Nav, Spinner } from "react-bootstrap";
import { drawingLogGraph } from "../../../../services/DashboardService";

const chartFilterData = [
    { title: 'W', type: 'weekly' },
    { title: 'M', type: 'monthly' },
];

const DrawingLogGraphPage = ({ loginLogdata }) => {
    const [timePeriod, setTimePeriod] = useState("weekly")
    const [series, setSeries] = useState([])
    const [labels, setLabels] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (loginLogdata) {
            setIsLoading(true);
            drawingLogGraph(timePeriod).then((resp) => {
                const { drawing_log_count, labels } = resp.data.results
                const series = [drawing_log_count, timePeriod === "weekly"? loginLogdata.weekly: loginLogdata.monthly]
                setSeries(series);
                setLabels(labels)
                setIsLoading(false);
            }).catch((error) => {
                if (error.response) {
                    console.log(error.response.data.message);
                    setIsLoading(false);
                } else {
                    console.log(error);
                }
            });
        }
    }, [timePeriod, loginLogdata])

    const options = {
        chart: {
            type: 'area',
            height: 180,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        colors: ['#00457b', "#3ac977"],
        legend: {
            horizontalAlign: 'center'
        },
        labels
    };

    return (
        <div className="card">
            <div className="card-header border-0 pb-0 flex-wrap">
                <div className="d-flex align-items-center">
                    <h3 className="mb-0 me-3">Drawings Log</h3>
                </div>
                <Nav as="ul" className="nav nav-pills mix-chart-tab">
                    {chartFilterData.map((item, index) => (
                        <Nav.Item as="li" className="nav-item" key={index} onClick={() => setTimePeriod(item.type)}>
                            <Nav.Link className={timePeriod === item.type && ' active'}>{item.title}</Nav.Link>
                        </Nav.Item>
                    ))}
                </Nav>
            </div>
            <div className="card-body  p-0">
                <div id="overiewChartdoc">
                    {isLoading ? <div className="d-flex align-items-center justify-content-center">
                        <h3 className="text-primary">Loading .. <Spinner size="sm" /></h3>
                    </div> : null}
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="area"
                        height={200}
                    />
                </div>
            </div>
        </div>
    );

}

export default DrawingLogGraphPage;