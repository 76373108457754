import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { downloadDrawingFiles } from "../../../services/DrawingService";
import { getDrawingDescriptionListAction, handleLoadingAction } from "../../../store/actions/DrawingActions";
import { formatFileSize } from "../../../utils";
import TableLoaderEffect from "../../constant/TableLoader";
import Pagination from "../../constant/Pagination";
import ReviseFileUploadPage from "./ReviseFileUpload";
import EditDescriptionModal from "./DescriptionEdit";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";


const DescriptionListPage = (props) => {
    const { descriptionList, descriptionCount, isDescriptionListLoading, isReviseFileUpload, isDescriptionSearchQueryLoading, userRole, drawing, drawingPerm } = props
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const recordsPage = 10;
    const dispatch = useDispatch()
    const { id } = useParams();

    useEffect(() => {
        dispatch(handleLoadingAction({ isDescriptionListLoading: true }))
        dispatch(handleLoadingAction({ isReviseFileUpload: false }))
    }, [])

    useEffect(() => {
        dispatch(getDrawingDescriptionListAction(id, currentPage, recordsPage, searchQuery))
    }, [currentPage, searchQuery]);


    const viewDrawingFile = async (id) => {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}/api/drawing_file_download/${id}`
            window.open(url, '_blank');
        } catch (error) {
            console.error('Error fetching or opening file:', error);
        }
    };

    const handleSearchChange = (e) => {
        const { value } = e.target;
        if (value) {
            dispatch(handleLoadingAction({ isDescriptionSearchQueryLoading: true }));
        }
        setCurrentPage(1)
        setSearchQuery(value);
    };

    const handlePagination = (page) => {
        const new_page = page.selected + 1;
        setCurrentPage(new_page);
    };

    const downloadDrawingFile = async (id, isDWGDownload = false) => {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}/api/drawing_file_download/${id}?action=download${isDWGDownload ? "&dwg_download=yes" : ''}`
            window.open(url, '_blank');
        } catch (error) {
            console.error('Error fetching or opening file:', error);
        }
    };

    const IsDownloadDrawingDWGFile = () => {
        if (drawing) {
            if (["SuperAdmin", "Admin"].includes(userRole)) {
                return true
            }
            return drawingPerm?.download_drawing
        } return false
    }

    const IsDisableDrawingDWGFile = () => {
        if (drawing) {
            if (["SuperAdmin", "Admin"].includes(userRole)) {
                return drawing.is_dwg_file_present
            }
            return !drawingPerm.disable_dwg_file && drawing.is_dwg_file_present
        } return false
    }

    return (isReviseFileUpload ? <ReviseFileUploadPage /> :
        <div>
            <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0 fw-bold">Description List</h4>
                    <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                        <div className="me-3 mb-3 mb-md-0">
                            <div className="input-group tp-input-group">
                                <input
                                    type="text"
                                    name="search"
                                    style={{ minWidth: '15rem' }}
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className="form-control form-control-sm"
                                    placeholder="Search"
                                />
                                <div className="input-group-text" id="basic-search">
                                    {isDescriptionSearchQueryLoading && (
                                        <Spinner variant="primary" size="sm" />
                                    )}
                                    {!isDescriptionSearchQueryLoading &&
                                        searchQuery.length > 0 ? (
                                        <i
                                            className="fa-solid fa-xmark c-pointer text-primary"
                                            onClick={() => {
                                                setCurrentPage(1);
                                                setSearchQuery("");
                                            }}
                                        ></i>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        {(userRole !== "User" && drawing) && (
                            <button
                                type="button"
                                onClick={() => {
                                    dispatch(handleLoadingAction({ isReviseFileUpload: true }))
                                    setCurrentPage(1)
                                    setSearchQuery("");
                                }}
                                className="btn btn-outline-primary btn-sm">
                                <i className="fa-solid fa-upload me-2"></i> Update Files
                            </button>
                        )}
                    </div>
                </div>

                <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer">
                    <table
                        id="empoloyees-tblwrapper"
                        className="table ItemsCheckboxSec dataTable no-footer mb-0">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Drawing Number</th>
                                <th>File Size</th>
                                <th>Action</th>
                                {IsDisableDrawingDWGFile() && (<th>DWG File</th>)}
                            </tr>
                        </thead>
                        <tbody>

                            {isDescriptionListLoading ? (
                                <TableLoaderEffect colLength={4} />
                            ) : descriptionList.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <EditDescriptionModal data={{ description: item.description, id: item.id }} drawing_id={id} />
                                    </td>
                                    {item.drawing_file ? (
                                        <>
                                            <td>
                                                <span>{item.drawing_file.file_name}</span>
                                            </td>
                                            <td>{formatFileSize(item.drawing_file.file_size)}</td>

                                            <td>
                                                <div className="d-flex align-item-center ">
                                                    {(drawing && (drawing.drawing_file_type === "PDF" || item.drawing_file.view_pdf_file)) &&
                                                        <button type="button"
                                                            className="btn btn-primary tp-btn btn-circle me-3"
                                                            onClick={() => viewDrawingFile(item.drawing_file.id)}>
                                                            <i className="fa-regular fa-eye"></i>
                                                        </button>

                                                    }
                                                    <button type="button"
                                                        className="btn btn-primary tp-btn btn-circle me-3"
                                                        onClick={() => downloadDrawingFile(item.drawing_file.id)}>
                                                        <i className="fa-solid fa-download"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </>
                                    )}

                                    {IsDisableDrawingDWGFile() && (
                                        <td>{item.drawing_file?.dwg_file ? (<div>
                                            <span className="text-success me-2">YES</span>
                                            {IsDownloadDrawingDWGFile() && (
                                                <button type="button"
                                                    className="btn btn-success tp-btn btn-circle me-3"
                                                    onClick={() => downloadDrawingFile(item.drawing_file.id, true)}>
                                                    <i className="fa-solid fa-download"></i>
                                                </button>
                                            )}
                                        </div>) : <span className="text-danger">NO</span>}</td>
                                    )}
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>

            </div>
            {isDescriptionListLoading ? (
                <div style={{ height: "4rem" }}></div>
            ) : (
                <Pagination
                    currentPage={currentPage}
                    recordsPage={recordsPage}
                    dataLength={descriptionCount}
                    handlePagination={handlePagination}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        descriptionList: state.drawing.drawingDescriptionList,
        descriptionCount: state.drawing.drawingDescriptionCount,
        isDescriptionListLoading: state.drawing.isDrawingDescriptionListLoading,
        isDescriptionSearchQueryLoading: state.drawing.isDescriptionSearchQueryLoading,
        isReviseFileUpload: state.drawing.isReviseFileUpload,
        userRole: state.auth.user?.role || "Admin",
        drawingPerm: state.auth.user?.drawing_permission || {},
        drawing: state.drawing.selectedDrawing
    };
};

export default connect(mapStateToProps)(DescriptionListPage); 
