import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserList } from "../../../services/UserService";
import Pagination from "../../constant/Pagination";
import TableLoaderEffect from "../../constant/TableLoader";
import { Spinner } from "react-bootstrap";

const UserPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const recordsPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true)
    getUserList(currentPage, recordsPage, searchQuery)
      .then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.count);
        setIsLoading(false);
        setIsSearchLoading(false)
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
        setIsSearchLoading(false)
      });
  }, [currentPage, searchQuery]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
    setIsSearchLoading(true)
    setCurrentPage(1)
  };

  const handlePagination = (page) => {
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="tbl-caption d-flex justify-content-between text-wrap flex-column  flex-sm-row align-items-start align-items-sm-center p-3">
                  <h4 className="heading mb-3 mb-md-0 fw-bold">User</h4>
                  <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                    <div className="me-3 mb-3 mb-md-0">
                      <div className="input-group tp-input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          value={searchQuery}
                          aria-describedby="basic-search"
                          onChange={handleSearchChange}
                        />
                        <div className="input-group-text" id="basic-search">
                          {isSearchLoading && (
                            <Spinner variant="primary" size="sm" />
                          )}
                          {!isSearchLoading && searchQuery.length > 0 && (
                            <i
                              className="fa-solid fa-xmark c-pointer text-primary"
                              onClick={() => {
                                setCurrentPage(1);
                                setSearchQuery("");
                              }}
                            ></i>
                          )}
                        </div>
                      </div>
                    </div>
                    <button
                      className="btn btn-primary"
                      onClick={() => navigate("/add-user")}
                    >
                      Add User
                    </button>
                  </div>
                </div>
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyees-tblwrapper"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0 table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Personnel No.</th>
                          <th>Full Name</th>
                          <th>Email</th>
                          <th>Phone No.</th>
                          <th>Role</th>
                          <th>Designation</th>
                          <th>Department</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <TableLoaderEffect colLength={6} />
                        ) : (
                          data.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <span
                                  className="text-primary cursor-pointer fw-bolder"
                                  onClick={() =>
                                    navigate(`/user/${item.id}`)
                                  }
                                >
                                  {item.personnel_number}
                                </span>
                              </td>
                              <td>
                                <span>{item.full_name}</span>
                              </td>
                              <td>{item.email}</td>
                              <td>
                                <span>{item.phone_number}</span>
                              </td>
                              <td>
                                {item.role === "User" ?
                                  <span className={`badge badge-sm rounded-pill border-0 badge-info`}>USER</span>
                                  :
                                  <span className={`badge badge-sm rounded-pill border-0 badge-primary`}>ADMIN</span>
                                }
                              </td>
                              <td>
                                <span>{item.designation}</span>
                              </td>
                              <td>
                                <span>{item.department.name}</span>
                              </td>
                              <td>
                                {item.status === "Active" ? (
                                  <span
                                    className={`badge light border-0 badge-success`}
                                  >
                                    Active
                                  </span>
                                ) : (
                                  <span
                                    className={`badge light border-0 badge-danger`}
                                  >
                                    Inactive
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {isLoading ? (
                  <div style={{ height: "4rem" }}></div>
                ) : (
                  <Pagination
                    currentPage={currentPage}
                    recordsPage={recordsPage}
                    dataLength={total}
                    handlePagination={handlePagination}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserPage;
