import React from "react";
import bg6 from "../../../images/background/bgilu.jpg";
import LoginPage from "./Login";

function HomePage() {

    return (
        <div className="home-containter"
            style={{ backgroundImage: `linear-gradient(#04233b, #2ebf91), url(${bg6})` }}>
            <div className="main-content d-flex align-items-center justify-content-center">
                <LoginPage />
            </div>
            <div className="home-footer">
               
            </div>
        </div>
    );
}

export default HomePage;