import React, { useState } from "react";
import { toast } from "react-toastify";
import { Modal, Form } from "react-bootstrap";
import { resetTwoFactorAuthApi } from "../../../services/UserService";
import { useDispatch } from "react-redux";
import { updateProfileAction } from "../../../store/actions/AuthActions";

function ResetTwoFactorAuth() {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch()

  const handleTogglePassword = () => {
    setShowPassword(!setShowPassword);
  };


  const toggleModal = () => {
    if (showModal) {
      setPassword("")
      setShowPassword(false)
    }
    setShowModal(!showModal);
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const resp = await resetTwoFactorAuthApi({ password });
      toast.success(resp.data.message);
      dispatch(updateProfileAction({ is_enable_two_factor: false }))
      toggleModal();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  return (
    <>
      <button
        className="btn btn-danger btn-sm cursor-pointer fw-bolder"
        onClick={toggleModal}
      >
        Reset 2FA
      </button>
      <Modal
        className="modal fade modal-sm"
        id="exampleModal1"
        show={showModal}
        centered={true}
        onHide={toggleModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Reset Two Factor Approval Authentication
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={handleSubmit}>
              <div className="row">
                <div className="mb-3">
                  <label htmlFor="current_password" className="form-label">
                    Password <span className="text-danger">*</span>
                  </label>
                  <div className="input-group ">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control border border-end-0"
                      id="password"
                      placeholder="Enter Password"
                      name="password"
                      autoComplete="new-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      className="btn border border-start-0 border-light-subtle pb-2 "
                      type="button"
                      onClick={() => handleTogglePassword("cPass")}
                    >
                      {showPassword.cPass ? (
                        <i className="fa-regular fa-eye-slash"></i>
                      ) : (
                        <i className="fa-regular fa-eye"></i>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-block btn-sm btn-primary mt-3 float-end"
              >
                Reset 2FA
              </button>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ResetTwoFactorAuth;
