import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Spinner } from 'react-bootstrap';

const PieChart = ({ level, series, title, isLoading }) => {
    const options = {
        labels: level,
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 300
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        legend: {
          show:false,
          position: 'bottom',
          horizontalAlign: 'center'
        }
      };

    return (
        <div className="card">
            <div className="card-header border-0 pb-0">
                <h4 className="mb-0 text-center">{title}</h4>
            </div>
            <div className="card-body  p-0">
                <div id="overiewChartdoc">
                    {isLoading ? <div className="d-flex align-items-center justify-content-center">
                        <h3 className="text-primary">Loading .. <Spinner size="sm" /></h3>
                    </div> : null}
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="pie"
                        width={300}
                    />
                </div>
            </div>
        </div>
    );
};

export default PieChart;
