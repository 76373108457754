import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
} from "../../../store/actions/AuthActions";
import { Spinner } from "react-bootstrap";

import logo from "../../../images/logo/logo.png";

import NewUserRequest from "./NewUserRequestForm";
function LoginPage({ showLoading }) {
  const navigate = useNavigate();
  const [personnel_number, setPersonnelNo] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  let errorsObj = { personnel_number: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");


  const dispatch = useDispatch();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  function onLogin(e) {
    e.preventDefault();
    if (!showLoading) {
      let error = false;
      const errorObj = { ...errorsObj };
      if (personnel_number === "") {
        errorObj.personnel_number = "Personnel Number is Required";
        error = true;
      }
      if (password === "") {
        errorObj.password = "Password is Required";
        error = true;
      }
      setErrors(errorObj);
      if (error) {
        return;
      }
      localStorage.setItem('lastActivityTime', Date.now().toString());
      dispatch(loadingToggleAction(true));
      dispatch(loginAction(personnel_number, password, navigate));
    }
  }

  return (

    <div className="p-4 bg-white rounded">
      <div
        style={{
          marginBottom: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={logo}
          alt=""
          width={"60px"}
          className="width-200 light-logo"
        />
      </div>
      <div className="nav nav-tabs border-bottom-0">
        <div className="tab-content w-100" id="nav-tabContent">
          <div className="tab-pane fade active show" id="nav-personal">
            <form className=" dz-form pb-1" onSubmit={onLogin}>
              <h3 className="form-title text-center">
                Login To Your Account
              </h3>
              <p className="text-center">Enter your Personnel Number and  Password. </p>
              <div className="dz-separator-outer m-b5">
                <div className="dz-separator bg-primary style-liner"></div>
              </div>
              <div className="form-group mb-3">
                <label>Personnel Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Personnel Number"
                  value={personnel_number}
                  onChange={(e) => setPersonnelNo(e.target.value)}
                />
                {errors.personnel_number && (
                  <div className="text-danger fs-12">
                    {errors.personnel_number}
                  </div>
                )}
              </div>
              <div className="form-group mb-3">
                <label>Password</label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control border border-end-0"
                    placeholder="Enter Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    className="btn border border-start-0 border-light-subtle pb-2"
                    type="button"
                    onClick={handleTogglePassword}
                  >
                    {showPassword ? (
                      <i className="fa-solid fa-eye-slash"></i>
                    ) : (
                      <i className="fa-solid fa-eye"></i>
                    )}
                  </button>
                </div>
                {errors.password && (
                  <div className="text-danger fs-12">
                    {errors.password}
                  </div>
                )}
              </div>

              <button
                type="submit"
                disabled={showLoading}
                className="btn btn-primary btn-block my-3"
              >
                {showLoading ? (
                  <>
                    <Spinner size="sm" /> Login
                  </>
                ) : (
                  "Login"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

  );
}

const mapStateToProps = (state) => {
  return {
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(LoginPage);