import * as Yup from "yup";

export const addDrawingSchema = Yup.object().shape({
  work_order_number: Yup.string().min(1),
  vendor_number: Yup.string().min(1),
  client_number: Yup.string().min(1),
  package_number: Yup.string().min(1, "Package number must be greater than one"),
  drawing_type: Yup.string().required("Please choose any drawing type"),
  drawing_file_type: Yup.string().required("Please choose any drawing file type"),
  department_id: Yup.string().when("drawing_type", ([drawing_type], schema) => {
    return ["PDR", "CDBR"].includes(drawing_type)
      ? schema.required("Please select department")
      : schema.optional();
  }),
  unit_id: Yup.string().when("drawing_type", ([drawing_type], schema) => {
    return ["PDR", "CDBR"].includes(drawing_type)
      ? schema.required("Please select unit")
      : schema.optional();
  }),
  volume: Yup.string().when("drawing_type", ([drawing_type], schema) => {
    return drawing_type === "RS"
      ? schema.required("Please select volume")
      : schema.optional();
  }),
  drawing_number: Yup.string().required("Drawing number is required"),
  drawing_size: Yup.string().required("Please Select drawing size"),
  supplier_name: Yup.string().min(1),
  remarks: Yup.string().min(1).required("Please enter remarks"),
  no_of_sheet: Yup.number()
    .min(1, "Number of sheets must be at least 1")
    .required("Number of sheets is required"),
  description: Yup.array()
    .of(Yup.string().required("Description is required"))
    .required("Description is required"),
  date_of_registration: Yup.date()
    .nullable()
    .min(new Date(1900, 0, 1)).when("drawing_type", ([drawing_type], schema) => {
      return ["PDR", "CDBR", "PS", "RS"].includes(drawing_type)
        ? schema.required("Please enter date of registration") : schema.optional();
    }),
  fdr_approved_date: Yup.date().nullable().min(new Date(1900, 0, 1)),
  letter_number: Yup.string().min(1),
  pdr_number: Yup.string().min(1),
  approver: Yup.string().when("drawing_file_type", ([drawing_file_type], schema) => {
    return drawing_file_type === "PDF" ? schema.required("Please select approver") : schema.optional();
  }),

});
