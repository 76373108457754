import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import DynamicSelectInput from "../../constant/SelectInput";
import { useFormik } from "formik";
import { editUserSchema } from "../../constant/schemas/EditUserSchema";
import { toast } from "react-toastify";
import { updateUser } from "../../../services/UserService";
import { Spinner } from "react-bootstrap";

function EditUserModal({ data, setData, userRole }) {
  const initialValues = {
    id: "",
    personnel_number: "",
    full_name: "",
    email: "",
    phone_number: "",
    role: userRole !== "SuperAdmin" ? "User" : "Admin",
    designation: "",
    is_approver: "NO",
    is_final_approver: "NO",
    department_id: "",
    drawing_permission: { add_drawing: false, ps: false, fdr: false, misc: false, disable_dwg_file: true, download_drawing: false, view_layout: false },
  };

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleModal = () => {
    if (showModal) {
      setValues(initialValues);
    } else {
      setValues({
        id: data.id,
        personnel_number: data.personnel_number,
        full_name: data.full_name,
        email: data.email,
        phone_number: data.phone_number,
        role: data.role,
        designation: data.designation,
        department_id: data.department.department_id,
        is_approver: data.is_an_approver ? "YES" : "NO",
        is_final_approver: data.is_final_approver ? "YES" : "NO",
        drawing_permission: data.drawing_permission,
      });
    }
    setShowModal(!showModal);
  };

  const handleSelectChange = (name, value, _) => {
    setValues({ ...values, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    try {
      setIsLoading(true);
      const resp = await updateUser(values);
      toast.success(resp.data.message);
      setData(resp.data.results);
      toggleModal();
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsLoading(false);
  };

  const handleDrawingPermission = (e) => {
    const { name, checked } = e.target
    if (name === "disable_dwg_file" && checked) {
      setValues({ ...values, drawing_permission: { ...values.drawing_permission, [name]: checked, download_drawing: false } });
    } else {
      setValues({ ...values, drawing_permission: { ...values.drawing_permission, [name]: checked } });
    }
  }


  const handleAllPermission = (name, checked, defaultValue) => {
    let permValue
    if (['drawing_permission', 'standard_permission', 'document_permission'].includes(name)) {
      if (checked) {
        Object.keys(defaultValue).forEach(key => defaultValue[key] = !defaultValue[key]);
        permValue = defaultValue
      } else {
        permValue = defaultValue
      }
    }
    if (permValue !== undefined) {
      setValues({ ...values, [name]: permValue });
    }
  }

  const PermissionRadioButton = ({ label, name, checkValue, handleChange, disabled = false }) => {
    return (
      <div className="mb-2 ms-3">
        <div className="form-check custom-checkbox mb-2 form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            name={name}
            id={`is_view_${name}`}
            checked={checkValue}
            disabled={disabled}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor={`is_view_${name}`}>{label}</label>
        </div>
      </div>
    )
  }

  const {
    values,
    errors,
    touched,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: editUserSchema,
    onSubmit: handleFormSubmit,
  });

  return (
    <>
      <button
        className="btn btn-outline-primary btn-sm cursor-pointer fw-bolder"
        onClick={toggleModal}
      >
        <i className="fa-solid fa-pen-to-square"></i> Edit
      </button>
      <Modal
        className="modal modal-xl fade"
        id="exampleModal1"
        show={showModal}
        scrollable
        onHide={toggleModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Edit User
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            {data ? (
              <Form onSubmit={handleSubmit} className="px-4 py-2">
                <div className="row">
                  <div className="col-xl-6 col-sm-12">
                    <div className="mb-3">
                      <label
                        htmlFor="personnel_number"
                        className="form-label"
                      >
                        Personnel No. <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="personnel_number"
                        placeholder="Enter Personnel Number"
                        name="personnel_number"
                        value={values.personnel_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.personnel_number && touched.personnel_number ? (
                        <div className="text-danger small mt-1">
                          {errors.personnel_number}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="full_name" className="form-label">
                        Full Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="full_name"
                        name="full_name"
                        placeholder="Enter Full Name"
                        value={values.full_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.full_name && touched.full_name ? (
                        <div className="text-danger small mt-1">
                          {errors.full_name}
                        </div>
                      ) : null}
                    </div>

                    {userRole === "SuperAdmin" && (
                      <>
                        <div className="mb-3">
                          <label>
                            Is Approver <span className="text-danger">*</span>
                          </label>
                          <div className="form-check custom-checkbox mb-2 ms-3 form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="is_approver"
                              id="approverYesRadio"
                              value="YES"
                              checked={values.is_approver === "YES"}
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="approverYesRadio"
                            >
                              YES
                            </label>
                          </div>
                          <div className="form-check custom-checkbox mb-2 form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="is_approver"
                              id="approverNoRadio"
                              value="NO"
                              checked={values.is_approver === "NO"}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setValues({ ...values, is_approver: "NO", is_final_approver: "NO" })
                                }
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="approverNoRadio"
                            >
                              NO
                            </label>
                          </div>
                        </div>

                        {values.is_approver === "YES" && (
                          <div className="mb-3">
                            <label>
                              Final Approver <span className="text-danger">*</span>
                            </label>
                            <div className="form-check custom-checkbox mb-2 ms-3 form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="is_final_approver"
                                id="finalapproverYesRadio"
                                value="YES"
                                checked={values.is_final_approver === "YES"}
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="finalapproverYesRadio"
                              >
                                YES
                              </label>
                            </div>
                            <div className="form-check custom-checkbox mb-2 form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="is_final_approver"
                                id="finalapproverNoRadio"
                                value="NO"
                                checked={values.is_final_approver === "NO"}
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="finalapproverNoRadio"
                              >
                                NO
                              </label>
                            </div>
                          </div>
                        )}
                        <div className="mb-3">
                          <label>
                            User Role <span className="text-danger">*</span>
                          </label>
                          <div className="form-check custom-checkbox mb-2 ms-3 form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="role"
                              id="adminRadio"
                              value="Admin"
                              checked={values.role === "Admin"}
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="adminRadio"
                            >
                              Admin
                            </label>
                          </div>
                          <div className="form-check custom-checkbox mb-2 form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="role"
                              id="userRadio"
                              value="User"
                              checked={values.role === "User"}
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="userRadio"
                            >
                              User
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-xl-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email ? (
                        <div className="text-danger small mt-1">
                          {errors.email}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="phone_number" className="form-label">
                        Phone Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="phone_number"
                        placeholder="Enter Phone Number"
                        name="phone_number"
                        value={values.phone_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.phone_number && touched.phone_number ? (
                        <div className="text-danger small mt-1">
                          {errors.phone_number}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <DynamicSelectInput
                        setParentInputValue={handleSelectChange}
                        endpoint_name="search/department"
                        name={"department_id"}
                        id={"department_id"}
                        label={"Department"}
                        placeholder={"Select Department"}
                        isRequired={true}
                        defaultValue={data.department ? {
                          value: data.department.department_id,
                          label: data.department.name,
                        } : null}
                      />
                      {errors.department_id && touched.department_id ? (
                        <div className="text-danger small mt-1">
                          {errors.department_id}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="designation" className="form-label">
                        Designation<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="designation"
                        placeholder="Enter Designation"
                        name="designation"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.designation}
                      />
                      {errors.designation && touched.designation ? (
                        <div className="text-danger small mt-1">
                          {errors.designation}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {values.role === "User" ? (
                  <div className="my-3 px-2">
                    <div className="d-flex align-items-center mb-3">
                      <input type="checkbox"
                        className="form-check-input"
                        name="drawing_permission"
                        checked={Object.keys(values.drawing_permission).filter(key => key !== "disable_dwg_file").every(key => values.drawing_permission[key])}
                        onChange={(e) => handleAllPermission('drawing_permission', e.target.checked, initialValues.drawing_permission)}
                      />
                      <h5 className="fst-italic mb-0 ms-2 fw-semibold">Drawing Permission:</h5>
                    </div>
                    <div className="row">

                      <div className="col-lg-3 col-md-6 col-sm-12 p-2">
                        <PermissionRadioButton
                          label={"Add Drawing"}
                          name={'add_drawing'}
                          checkValue={values.drawing_permission.add_drawing}
                          handleChange={handleDrawingPermission}
                        />
                      </div>

                      <div className="col-lg-3 col-md-6 col-sm-12 p-2">
                        <PermissionRadioButton
                          label={"Disable DWG File"}
                          name={'disable_dwg_file'}
                          checkValue={values.drawing_permission.disable_dwg_file}
                          handleChange={handleDrawingPermission}
                        />
                      </div>

                      <div className="col-lg-3 col-md-6 col-sm-12 p-2">
                        <PermissionRadioButton
                          label={"Download Drawing"}
                          name={'download_drawing'}
                          checkValue={values.drawing_permission.download_drawing}
                          handleChange={handleDrawingPermission}
                          disabled={values.drawing_permission.disable_dwg_file}
                        />
                      </div>

                      <div className="col-lg-3 col-md-6 col-sm-12 p-2">
                        <PermissionRadioButton
                          label={"View Layout"}
                          name={'view_layout'}
                          checkValue={values.drawing_permission.view_layout}
                          handleChange={handleDrawingPermission}
                        />
                      </div>

                      <div className="col-lg-3 col-md-6 col-sm-12 p-2">
                        <PermissionRadioButton
                          label={"View PS Drawing"}
                          name={'ps'}
                          checkValue={values.drawing_permission.ps}
                          handleChange={handleDrawingPermission}
                        />
                      </div>

                      <div className="col-lg-3 col-md-6 col-sm-12 p-2">
                        <PermissionRadioButton
                          label={"View FDR Drawing"}
                          name={'fdr'}
                          checkValue={values.drawing_permission.fdr}
                          handleChange={handleDrawingPermission}
                        />
                      </div>

                      <div className="col-lg-3 col-md-6 col-sm-12 p-2">
                        <PermissionRadioButton
                          label={"View MISC Drawing"}
                          name={'misc'}
                          checkValue={values.drawing_permission.misc}
                          handleChange={handleDrawingPermission}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="d-flex align-items-center justify-content-center mt-2">
                  <button type="submit" className="btn btn-primary me-1 w-50" disabled={isLoading}>
                    {isLoading ? (<span><Spinner size="sm" className="me-2" />Updating...</span>) : "Update"}
                  </button>
                </div>
              </Form>
            ) : null}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditUserModal;
