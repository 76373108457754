import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tab, Nav, Form, Modal, Spinner, Placeholder } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { approveDrawing } from "../../../services/DrawingService";
import {
  getDrawingAction,
  handleLoadingAction,
  updateDrawingAction,
} from "../../../store/actions/DrawingActions";

import DescriptionListPage from "./DescriptionList";
import LogList from "./LogList";
import ArchiveDrawingModal from "./ArchiveDrawing";
import { toast } from "react-toastify";
import ApprovalTimeLine from "./ApprovalTimeLine";


const DrawingDetailsLoading = () => {
  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-item-center justify-content-between">
            <div className="w-100 px-2">
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
              </Placeholder>
            </div>

            <div className="w-100 px-2">
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
              </Placeholder>
            </div>
          </div>

          <div className="w-100 px-2 mt-3">
            <Placeholder animation="glow">
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
            </Placeholder>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-item-center justify-content-between">
            <div className="w-100 px-2">
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
              </Placeholder>
            </div>

            <div className="w-100 px-2">
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
              </Placeholder>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function DrawingDetailPage(prop) {
  const { drawing, isDrawingLoading, userRole, isApprover, userId } = prop;
  const [showModal, setShowModal] = useState(false);
  const [isApprovedLoading, setIsApprovedLoading] = useState(false);
  const [isApprovedCheck, setIsApprovedCheck] = useState(false);


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(handleLoadingAction({ isDrawingLoading: true }));
    dispatch(getDrawingAction(id));
  }, [id]);

  const toggleModal = () => {
    if (showModal) {
      setIsApprovedCheck(false);
      setIsApprovedLoading(false);
    }
    setShowModal(!showModal);
  };

  const handleApprovedSubmit = async (e) => {
    e.preventDefault();
    if (!isApprovedCheck) {
      return;
    }
    try {
      setIsApprovedLoading(true);
      const resp = await approveDrawing({ id: drawing.id });
      toast.success(resp.data.message);
      dispatch(updateDrawingAction({ ...drawing, is_approved: true }));
      toggleModal();
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsApprovedLoading(false);
  };

  return (

    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4">
            {isDrawingLoading ? (
              <DrawingDetailsLoading />
            ) : drawing ? (
              <div>
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex  align-items-center">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-circle"
                        onClick={() => navigate("/drawing")}
                      >
                        <i className="fa-solid fa-arrow-left"></i>
                      </button>
                      <h4 className="mb-0 ms-2">Drawing Details</h4>
                    </div>
                    <div>
                      {userRole !== "User" && (
                        <button
                          className="btn btn-outline-primary btn-sm cursor-pointer fw-bolder"
                          onClick={() => navigate(`/edit-drawing/${id}`)}>
                          <i className="fa-solid fa-pen-to-square"></i> Edit
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <span>Drawing Number</span>
                          <span className="fw-semibold">
                            {drawing.drawing_number}
                          </span>
                        </div>
                        {/* item end */}
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <span>Drawing Type</span>
                          <span className="fw-semibold">
                            {drawing.drawing_type}
                          </span>
                        </div>
                        {/* item end */}
                        {(userRole !== "User" || (isApprover)) && (
                          <>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>File Type</span>
                              <span className="fw-semibold">
                                {drawing.drawing_file_type}
                              </span>
                            </div>
                            {/* item end */}
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Status </span>
                              {drawing.is_approved === true ? (
                                <span
                                  className={`badge badge-sm ms-2 light border-0 badge-success`}
                                >
                                  Approved
                                </span>
                              ) : (
                                <span
                                  className={`badge badge-sm ms-2 light border-0 badge-info ${drawing.is_file_present &&
                                    userRole === "SuperAdmin" &&
                                    "cursor-pointer"
                                    }`}
                                  onClick={() => {
                                    if (
                                      drawing.is_file_present &&
                                      userRole === "SuperAdmin"
                                    ) {
                                      toggleModal();
                                    }
                                  }}
                                >
                                  Pending
                                </span>
                              )}
                            </div>
                          </>
                        )}
                        {/* items end */}
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <span>Revision</span>
                          <span className="fw-semibold">
                            {drawing.revision_version}
                          </span>
                        </div>
                        {/* item end */}
                      </div>
                      {/* col end */}
                      <div className="col-sm-12 col-md-6 border-start pb-0">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <span>No of Sheet</span>
                          <span className="fw-semibold">
                            {drawing.no_of_sheet}
                          </span>
                        </div>
                        {/* item end */}
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <span>Drawing Size</span>
                          <span className="fw-semibold">
                            {drawing.drawing_size}
                          </span>
                        </div>
                        {/* item end */}
                        {(userRole !== "User" || isApprover) && (
                          <>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Is Layout</span>
                              <span className="fw-semibold">
                                {drawing.is_layout ? (
                                  <span className="fw-semibold text-success">YES</span>
                                ) : (
                                  <span className="fw-semibold text-danger">NO</span>
                                )}
                              </span>
                            </div>
                            {/* item end */}
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>All File Present</span>
                              {drawing.is_file_present === true ? (
                                <span className="fw-semibold text-success">YES</span>
                              ) : (
                                <span className="fw-semibold text-danger">NO</span>
                              )}
                            </div>
                            {/* iteme end */}
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>DWG File</span>
                              {drawing.is_dwg_file_present === true ? (
                                <span className="fw-semibold text-success">YES</span>
                              ) : (
                                <span className="fw-semibold text-danger">NO</span>
                              )}
                            </div>
                            {/* item end */}
                          </>
                        )}

                      </div>
                      {/* col end */}

                      <div className="col-sm-12 col-md-12 border-top pt-0">
                        <div className="d-flex align-items-center justify-content-between my-3">
                          <span>Department</span>
                          <span className="fw-semibold ms-4">
                            {drawing.department?.name || "None"}
                          </span>
                        </div>
                        {/* item end */}
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <span>Unit</span>
                          <span className="fw-semibold ms-4">
                            {drawing.unit?.name || "None"}
                          </span>
                        </div>
                        {drawing.volume && (
                          <>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Volume</span>
                              <span className="fw-semibold ms-4">
                                {drawing.volume?.volume_name || "None"}
                              </span>
                            </div>

                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Sub-Volume</span>
                              <span className="fw-semibold ms-4">
                                {drawing.volume?.name || "None"}
                              </span>
                            </div>

                          </>
                        )}

                        {/* item end */}
                        {(userRole !== "User") && (
                          <>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Supplier Name</span>
                              <span className="fw-semibold ms-4">
                                {drawing.supplier_name || "None"}
                              </span>
                            </div>
                            {/* item end */}
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Vendor Number</span>
                              <span className="fw-semibold ms-4">
                                {drawing.vendor_number || "None"}
                              </span>
                            </div>
                            {/* item end */}
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Client Number</span>
                              <span className="fw-semibold ms-4">
                                {drawing.client_number || "None"}
                              </span>
                            </div>
                            {/* item end */}
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Package Number</span>
                              <span className="fw-semibold ms-4">
                                {drawing.package_number || "None"}
                              </span>
                            </div>
                          </>
                        )}
                        {/* item end */}
                        {["PDR", "CDBR", "PS", "RS"].includes(drawing.drawing_type) && <>
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <span>Date Of Registration</span>
                            <span className="fw-semibold ms-4">
                              {drawing.date_of_registration}
                            </span>
                          </div>
                        </>}
                        {/* item end */}

                        {drawing.drawing_type === "FDR" && (
                          <>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>PDR Number</span>
                              <span className="fw-semibold ms-4">
                                {drawing.pdr_number}
                              </span>
                            </div>

                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Letter No. & Date</span>
                              <span className="fw-semibold ms-4">
                                {drawing.letter_number}
                              </span>
                            </div>

                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Approved Date</span>
                              <span className="fw-semibold ms-4">
                                {drawing.fdr_approved_date}
                              </span>
                            </div>
                          </>
                        )}

                        {/* item end */}

                        {["PDR", "CDBR", "PS", "RS"].includes(drawing.drawing_type) && <>
                          <div className="d-flex align-items-center justify-content-between my-3">
                            <span>Work Order Number</span>
                            <span className="fw-semibold ms-4">
                              {drawing.work_order_number || "None"}
                            </span>
                          </div>
                          {/* item end */}
                          {(userRole !== "User") && (
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Certification</span>
                              <span className="fw-semibold ms-4">
                                {drawing.certification.toUpperCase()}
                              </span>
                            </div>
                          )}
                        </>}

                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <span>Remarks</span>
                          <span className="fw-semibold ms-4">{drawing.remarks}</span>
                        </div>
                        {/* item end */}
                        {drawing.archive_reason && (<div className="d-flex align-items-center justify-content-between mb-3">
                          <span>Archive Reason</span>
                          <span className="fw-semibold ms-4">{drawing.archive_reason}</span>
                        </div>)}

                        {/* item end */}
                      </div>
                      {/* col end */}
                    </div>
                  </div>
                </div>
                {drawing.approvals.length > 0 && <ApprovalTimeLine
                  approvalList={drawing.approvals}
                  uploadBy={{ ...drawing.uploaded_by, uploaded_at: drawing.uploaded_at }}
                  userId={userId}
                  drawing={drawing}
                />}
                {userRole === "SuperAdmin" && (
                  <div className="card">
                    <div className="card-body">
                      <div className="mb-3 d-flex align-items-center justify-content-between">
                        <ArchiveDrawingModal data={drawing} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="card">
                <div className="card-body">
                  <h4 className="text-center text-danger">
                    ⚠️ Drawing Not Found!
                    <br /> ID {id}
                  </h4>
                </div>
              </div>
            )}
          </div>

          <div className="col-lg-8">
            <div className="card">
              <div className="custom-tab-1">
                <Tab.Container defaultActiveKey={"description"}>
                  <Nav as="ul"
                    className="nav-pills mb-2 light border-bottom px-4 pt-3">
                    <Nav.Item as="li">
                      <Nav.Link eventKey={"description"}
                        className="pb-3 fw-semibold fs-5">Description</Nav.Link>
                    </Nav.Item>
                    {userRole !== "User" && (
                      <Nav.Item as="li">
                        <Nav.Link eventKey={'log'}
                          className="pb-3 fw-semibold fs-5">Log</Nav.Link>
                      </Nav.Item>
                    )}
                  </Nav>

                  <Tab.Content className="">
                    <Tab.Pane eventKey={"description"}>
                      <DescriptionListPage />
                    </Tab.Pane>
                    {userRole !== "User" && (
                      <Tab.Pane eventKey={"log"}>
                        <LogList />
                      </Tab.Pane>
                    )}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal fade modal-sm"
        id="exampleModal1"
        show={showModal}
        onHide={toggleModal}
        centered={true}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Approve Drawing
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={handleApprovedSubmit}>
              <div className="mb-3">
                <div className="form-check custom-checkbox mb-2 form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="approve"
                    checked={isApprovedCheck}
                    id="approve"
                    value="Approve"
                    onChange={(e) => setIsApprovedCheck(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="approve">
                    Approve
                  </label>
                </div>
              </div>
              <button
                type="submit"
                disabled={!isApprovedCheck || isApprovedLoading}
                className="btn btn-block btn-sm btn-primary mt-3 float-end"
              >
                {isApprovedLoading ? (
                  <span>
                    <Spinner animation="border" size="sm" /> Approve
                  </span>
                ) : (
                  "Approve"
                )}
              </button>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    drawing: state.drawing.selectedDrawing,
    isDrawingLoading: state.drawing.isDrawingLoading,
    userRole: state.auth.user?.role || "Admin",
    isApprover: state.auth.user.is_an_approver,
    userId:state.auth.user?.id || ""
  };
};
export default connect(mapStateToProps)(DrawingDetailPage);
