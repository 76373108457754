import React, { useContext, useReducer, useState, useEffect } from "react";
import Collapse from "react-bootstrap/Collapse";
import { Link, useLocation } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { SVGICON } from "../../constant/theme";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import LogoutPage from "./Logout";
import { navtoggle } from "../../../store/actions/AuthActions";
import { MenuList } from "./Menu";
import { getUserNameIcon } from "../../../utils";
import DateTimeDisplay from "./DateTimeDisplay";
import NoticeMarquee from "./NoticeMarquee";
const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});


const SideBar = ({ user, role }) => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } = useContext(ThemeContext);
  const dispatch = useDispatch();

  const [state, setState] = useReducer(reducer, { activeMenu: "/dashboard", activeSubmenu: "" });
  const [hideOnScroll, setHideOnScroll] = useState(true);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) {
        setHideOnScroll(isShow);
      }
    },
    [hideOnScroll]
  );

  const location = useLocation();


  useEffect(() => {
    const { menuActive, submenuActive } = getActiveMenuSubmenu(location.pathname)
    if (state.activeMenu !== menuActive || state.activeSubmenu !== submenuActive) {
      setState({ activeMenu: menuActive, activeSubmenu: submenuActive });
      if (!submenuActive) {
        setIsSubMenuOpen(false)
      }
    }
  }, [location]);


  function getActiveMenuSubmenu(url_name) {
    let menuActive = "";
    let submenuActive = "";
    for (const menu of MenuList) {
      if (menu.to === url_name) {
        menuActive = menu.to;
        break;
      } else if (menu.content) {
        for (const submenu of menu.content) {
          if (submenu.to === url_name) {
            menuActive = menu.to;
            submenuActive = submenu.to;
            break;
          }
        }
      }
    }
    return { menuActive, submenuActive }
  }

  const handleMenuActive = (isSubmenu) => {
    if (isSubmenu) {
      setIsSubMenuOpen(!isSubMenuOpen)
    }
    if (window.innerWidth <= 767) {
      dispatch(navtoggle());
    }
  };




  return (
    <div className={`deznav  border-right ${iconHover} ${sidebarposition.value === "fixed" && sidebarLayout.value === "horizontal" && headerposition.value === "static" ? hideOnScroll > 120 ? "fixed" : "" : ""}`}>
      <div className="deznav-scroll">
        <ul className="metismenu" id="menu">
          {MenuList.map((data, index) => (
            <MenuItem key={index}
              data={data} state={state} handleMenuActive={handleMenuActive}
              menuClass={data.classsChange}
              isSubMenuOpen={isSubMenuOpen}
              role={role} />
          ))}
        </ul>
        {/* {role === "User" && <div className="notice-time">
          <DateTimeDisplay isCard={true} />
          <NoticeMarquee />
        </div>
        } */}
      </div>
      <UserProfile user={user} />
    </div>
  );
};

const MenuItem = ({ data, state, handleMenuActive, handleSubmenuActive, menuClass, isSubMenuOpen, role }) => {
  const checkUrlAccess = () => {
    if (data.accessRole.includes(role)) {
      return true
    } return false
  }

  return checkUrlAccess() ? (
    <li className={` ${state.activeMenu === data.to ? "mm-active" : ""}`}>
      {data.content && data.content.length > 0 ? (
        <>
          <Link to={"#"} className="has-arrow"
            onClick={() => handleMenuActive(true)}>
            <div className="menu-icon">{data.iconStyle}</div>
            <span className="nav-text"> {data.title}</span>
          </Link>
          <Collapse in={isSubMenuOpen}>
            <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
              {data.content && data.content.map((subData, index) => (
                <SubMenuItem key={index} subData={subData} state={state} handleSubmenuActive={handleSubmenuActive} />
              ))}
            </ul>
          </Collapse>
        </>
      ) : (
        <Link to={data.to} onClick={() => handleMenuActive(false)}>
          <div className="menu-icon">{data.iconStyle}</div>
          <span className="nav-text">{data.title}</span>
        </Link>
      )}
    </li>
  ) : null;
};

const SubMenuItem = ({ subData, state }) => {
  return (
    <li className={`${state.activeSubmenu === subData.to ? "mm-active" : ""}`}>
      <Link to={subData.to}>{subData.title}</Link>
    </li>
  );
};

const UserProfile = ({ user }) => {

  return (
    <>
      <div className="user-profile">
        <div className="card user-profile-card">
          <Link className="user-profile-item" to={"/profile"}>
            {user?.profile_photo ? (
              <div className="profile-pic-sm">
                <img src={`${process.env.REACT_APP_BASE_URL}${user.profile_photo}`} alt="img" />
              </div>
            ) : (
              <div className="user-profile-icon">
                <span className="avatar-text">{getUserNameIcon(user?.full_name || "")}</span>
              </div>
            )}
            <div className="user-profile-text user-name ms-2">
              <h6>{user?.full_name}</h6>
              <span>PL No. {user?.personnel_number}</span>
            </div>
          </Link>
          <Link className="user-profile-item" to={"/profile"}>
            <span className="user-profile-icon">{SVGICON.MenuProfile}</span>
            <span className="user-profile-text">Profile</span>
          </Link>
          <LogoutPage />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    sideMenu: state.sideMenu,
    role: state.auth.user?.role || "User"
  };
};

export default connect(mapStateToProps)(SideBar);
