import { Suspense, useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import {
  checkAutoLoginAction,
  getVisitorsCountAction,
  Logout,
  updateLastActivityTimeAction
} from "./store/actions/AuthActions";
import "./other/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./App.css"
import Index from "./jsx";

function App({ isAuthenticated, lastActivityTime }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const formatTime = (time) => {
    const minutes = Math.floor(time / (60 * 1000));
    const seconds = Math.floor((time % (60 * 1000)) / 1000);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  useEffect(() => {
    dispatch(checkAutoLoginAction(navigate));
    if (isAuthenticated !== null) {
      dispatch(getVisitorsCountAction());
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    if (isAuthenticated !== null) {
      const idleTimeout = 15 * 60 * 1000; // 15 minutes in milliseconds
      const idleWarningTime = 2 * 60 * 1000; // 2 minutes in milliseconds

      const resetTimer = () => {
        const currentTime = Date.now();
        localStorage.setItem('lastActivityTime', currentTime.toString());
        dispatch(updateLastActivityTimeAction(currentTime));
      };

      const handleUserActivity = () => {
        resetTimer();
        setIsModalOpen(false);
      };

      const checkActivityTime = () => {
        const lastActivityTime = parseInt(localStorage.getItem('lastActivityTime'), 10) || Date.now();
        const currentTime = Date.now();
        const elapsedTime = currentTime - lastActivityTime;
        const remaining = idleTimeout - elapsedTime;

        if (remaining <= 0) {
          dispatch(Logout(navigate));
          console.log("sss logout here")
          setRemainingTime(0);
          setIsModalOpen(false);
        } else if (remaining <= idleWarningTime) {
          setIsModalOpen(true);
          setRemainingTime(remaining);
        } else {
          setIsModalOpen(false);
        }
      };

      const activityTimer = setInterval(checkActivityTime, 1000);
      window.addEventListener("mousemove", handleUserActivity);
      window.addEventListener("keypress", handleUserActivity);

      const handleStorageEvent = (event) => {
        if (event.key === 'lastActivityTime') {
          checkActivityTime();
        }
      };

      window.addEventListener('storage', handleStorageEvent);

      return () => {
        clearInterval(activityTimer);
        window.removeEventListener("mousemove", handleUserActivity);
        window.removeEventListener("keypress", handleUserActivity);
        window.removeEventListener('storage', handleStorageEvent);
      };
    }
  }, [isAuthenticated, lastActivityTime, dispatch, navigate]);

  return (
    <Suspense
      fallback={
        <div id="preloader">
          <div className="sk-three-bounce">
            <div className="sk-child sk-bounce1"></div>
            <div className="sk-child sk-bounce2"></div>
            <div className="sk-child sk-bounce3"></div>
          </div>
        </div>
      }
    >
      <Index />
      <Modal
        className="modal modal-md fade"
        id="exampleModal1"
        show={isModalOpen}
        onHide={() => toggleModal()}
        centered={true}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="text-primary fw-bold fs-4 flex-fill text-center" id="exampleModalLabel1">
              Are You Still Working?
            </h1>
          </div>
          <div className="modal-body">
            <p className="text-center fs-5 fw-semibold">You will be logged out in a few minutes due to inactivity.</p>
            <p className="text-center">Remaining Time <strong>{formatTime(remainingTime)}</strong></p>
            <div className="d-flex justify-content-center align-items-center">
              <button className="btn btn-primary" onClick={toggleModal}>Continue Working</button>
            </div>
          </div>
        </div>
      </Modal>
    </Suspense>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.user,
    lastActivityTime: state.auth.lastActivityTime,
  };
};

export default connect(mapStateToProps)(App);
