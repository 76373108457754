import React, { useState } from 'react'
import { Modal, Spinner, Form } from 'react-bootstrap';
import { getUserNameIcon } from '../../../utils'
import { multilevelDrawingApproval } from '../../../services/DrawingService';
import { toast } from 'react-toastify';
import DynamicSelectInput from '../../constant/SelectInput';
import { useDispatch } from 'react-redux';
import { updateDrawingAction } from '../../../store/actions/DrawingActions';

const ApprovalTimeLine = ({ approvalList, uploadBy, userId, drawing }) => {
    const [showModal, setShowModal] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [approvalInput, setApprovalInput] = useState({
        id: "",
        drawing_id: "",
        approval_type: "NEXT",
        next_approver: "",
        is_final_approver: false,
        otp: "",
    })
    const [approvalError, setApprovalError] = useState({ next_approver: false, otp: false })
    const dispatch = useDispatch()
    const toggleModal = () => {
        if (showModal) {
            setApprovalInput({
                id: "",
                drawing_id: "",
                approval_type: "NEXT",
                next_approver: "",
                is_final_approver: "",
                otp: "",
            })
            setIsSubmitLoading(false)
        }
        setShowModal(!showModal);
    };

    const handleSelectChange = (name, value, _) => {
        setApprovalInput({ ...approvalInput, [name]: value });
        if (value) {
            setApprovalError({ ...approvalError, next_approver: false })
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target
        if (name === "approval_type" && value === "FINAL") {
            setApprovalInput({ ...approvalInput, [name]: value, next_approver: "" });
        } else {
            setApprovalInput({ ...approvalInput, [name]: value });
        }
        if (name === "otp" && value) {
            setApprovalError({ ...approvalError, otp: false })
        }
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        let error = approvalError
        let isValid = true
        if (!approvalInput.otp) {
            error = { ...error, otp: true }
            isValid = false
        }
        if (approvalInput.approval_type === "NEXT" &&
            !approvalInput.is_final_approver &&
            !approvalInput.next_approver) {
            error = { ...error, next_approver: true }
            isValid = false
        }
        if (isValid) {
            setIsSubmitLoading(true);
            try {
                const resp = await multilevelDrawingApproval(approvalInput);
                const { results } = resp.data
                const isFinalApproved = results.every(item => item.status === "Approved")
                dispatch(updateDrawingAction({ ...drawing, is_approved: isFinalApproved, approvals: results }))
                toast.success(resp.data.message);
                toggleModal();
                setIsSubmitLoading(false);

            } catch (error) {
                console.log("error", error);
                toast.error(error.response.data.message);
                setIsSubmitLoading(false);
            }
        } else {
            setApprovalError({ ...error })
        }

    };

    return (
        <>
            <div className='card p-0'>
                <h4 className='border-bottom py-2 px-3'>Approvals</h4>
                <div className='p-3'>
                    {approvalList.map(item => (
                        <div className='approval-card d-flex align-items-center justify-content-between mb-3' key={item.id}>
                            <div className='d-flex align-items-center'>
                                {item.approver.profile_photo ? (
                                    <div className="profile-pic-sm">
                                        <img src={`${process.env.REACT_APP_BASE_URL}${item.approver.profile_photo}`} alt="img" />
                                    </div>
                                ) : (
                                    <div className="user-profile-icon">
                                        <span className="avatar-text">{getUserNameIcon(item.approver.full_name || "")}</span>
                                    </div>
                                )}
                                <div className="d-flex flex-column ms-3">
                                    <span className='fw-bold'>{item.approver.full_name}</span>
                                    <span>PL NO. {item.approver.personnel_number}</span>
                                </div>
                            </div>
                            <div className='d-flex flex-column align-items-end'>
                                {item.status === "Pending" ? (
                                    <span>
                                        {userId === item.approver.id ?
                                            <span className='badge badge-sm rounded badge-warning c-pointer'
                                                onClick={() => {
                                                    toggleModal()
                                                    setApprovalInput({
                                                        id: item.id,
                                                        drawing_id: drawing.id,
                                                        approval_type: "NEXT",
                                                        next_approver: "",
                                                        is_final_approver: item.approver.is_final_approver,
                                                        otp: "",
                                                    })
                                                }}>PENDING</span> :
                                            <span className='badge badge-sm rounded badge-warning'>PENDING</span>}
                                    </span>
                                ) : item.status === "Approved" ? (
                                    <span className='badge badge-sm rounded badge-success'>APPROVED</span>
                                ) : (
                                    <span className='badge badge-sm rounded badge-danger'>JEJECTED</span>
                                )}
                                <span>{item.approved_at}</span>
                            </div>
                        </div>
                    ))}
                    <div className='approval-card d-flex align-items-center justify-content-between mb-3'>
                        <div className='d-flex align-items-center'>
                            {uploadBy.profile_photo ? (
                                <div className="profile-pic-sm">
                                    <img src={`${process.env.REACT_APP_BASE_URL}${uploadBy.profile_photo}`} alt="img" />
                                </div>
                            ) : (
                                <div className="user-profile-icon">
                                    <span className="avatar-text">{getUserNameIcon(uploadBy.full_name || "")}</span>
                                </div>
                            )}
                            <div className="d-flex flex-column ms-3">
                                <span className='fw-bold'>{uploadBy.full_name}</span>
                                <span>PL NO. {uploadBy.personnel_number}</span>
                            </div>
                        </div>
                        <div className='d-flex flex-column align-items-end'>
                            <div className=''>
                                <span className='badge badge-sm rounded badge-primary'>UPLOADED</span>
                            </div>
                            <span className='mt-2'>{uploadBy.uploaded_at}</span>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                className="modal fade"
                id="exampleModal1"
                show={showModal}
                centered={true}
                onHide={toggleModal}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">
                            Approve Drawing
                        </h1>
                        <button type="button" className="btn-close" onClick={toggleModal}></button>
                    </div>
                    <div className="modal-body">
                        <Form onSubmit={handleOnSubmit}>
                            {approvalInput.is_final_approver ? <div>

                            </div> : (
                                <div>
                                    <div className="row mb-3">
                                        <label className="col-sm-3 col-form-label">
                                            Approver <span className="text-danger">*</span>
                                        </label>
                                        <div className='col-sm-9'>
                                            <div className="form-check custom-checkbox mb-2 form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="approval_type"
                                                    id="approverNextRadio"
                                                    value="NEXT"
                                                    checked={approvalInput.approval_type === "NEXT"}
                                                    onChange={handleInputChange}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="approverNextRadio"
                                                >
                                                    Next Approver
                                                </label>
                                            </div>
                                            <div className="form-check custom-checkbox mb-2 form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="approval_type"
                                                    id="approverFinalRadio"
                                                    value="FINAL"
                                                    checked={approvalInput.approval_type === "FINAL"}
                                                    onChange={handleInputChange}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="approverFinalRadio">
                                                    Final Approver
                                                </label>
                                            </div>
                                        </div>

                                    </div>

                                    {approvalInput.approval_type === "NEXT" &&
                                        <div className="form-group row mb-3">
                                            <label
                                                htmlFor={"next_approver"}
                                                className="col-sm-3 col-form-label">
                                                Next Approver
                                                <strong className="text-danger">*</strong>
                                            </label>
                                            <div className="col-sm-9">
                                                <DynamicSelectInput
                                                    parentClassName="mb-0"
                                                    setParentInputValue={handleSelectChange}
                                                    endpoint_name={`approver_list/${drawing.id}`}
                                                    name={"next_approver"}
                                                    id={"next_approver"}
                                                    placeholder={"Select Next Approver"}
                                                    isRequired={true}
                                                />
                                                {approvalError.next_approver ? (
                                                    <div className="text-danger small mt-1">
                                                        Select next approver
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    }
                                </div>)}

                            <div className="form-group row mb-3">
                                <label
                                    htmlFor="otp"
                                    className="col-sm-3 col-form-label"
                                >
                                    2FA OTP
                                </label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="otp"
                                        placeholder="Enter 2FA OTP"
                                        name="otp"
                                        value={approvalInput.otp}
                                        onChange={handleInputChange}
                                    />
                                    {approvalError.otp ? (
                                        <div className="text-danger small mt-1">
                                            Enter 2FA OTP
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <button
                                type="submit"
                                className="btn btn-block btn-sm btn-primary mt-3 float-end"
                                disabled={isSubmitLoading}
                            >
                                {isSubmitLoading ? (
                                    <span>
                                        <Spinner variant="light" size="sm" className="ms-2" />Approving..
                                    </span>
                                ) : ("Approve")}
                            </button>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ApprovalTimeLine